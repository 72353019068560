"use strict"

angular.module("informaApp")
    .service("AppcuesService", function ($window, Environments) {
        function shouldBeDisabled() {
            return window.envVariables.environment === Environments.production || isAppcuesUndefined();
        }

        function isAppcuesUndefined() {
            var appcues = window.Appcues;

            if (!appcues){
                console.log("Appcues is undefined!")
            }

            return !appcues;
        }

        return {
            identify: function (id, email) {
                if (shouldBeDisabled()){
                    return;
                }

                Appcues.identify(id, {
                    email: email
                });
            },
            anonymous: function () {
                if (shouldBeDisabled()){
                    return;
                }

                Appcues.anonymous();
            },
            restart: function () {
                if (shouldBeDisabled()){
                    return;
                }

                if ($window.Appcues) {
                    $window.Appcues.start();
                }
            }
        };
    });